<template>
  <div>
    <b-card title="Purple MDM 🚀">
      <b-card-text>
        Provision, manage and check the compliance of all your devices.
        Please make sure to read our <b-link href="#" target="_blank">FAQ</b-link> to understand some of the
        concepts and terminology used in our MDM solution.
      </b-card-text>
    </b-card>

    <date-range-selector @update="updateDateRange" />

    <b-row v-if="$can('Read', 'Owner')" class="match-height">
      <b-col sm="12" md="6">
        <top-activities :from="fromDateISOString" :to="toDateISOString" />
      </b-col>
      <b-col sm="12" md="6">
        <top-tags :from="fromDateISOString" :to="toDateISOString" />
      </b-col>
    </b-row>

    <b-row v-if="$can('Read', 'Owner')" class="match-height">
      <b-col>
        <logins :from="fromDateISOString" :to="toDateISOString" />
      </b-col>
    </b-row>

    <b-row v-if="$can('Read', 'Owner')" class="match-height">
      <b-col>
        <logins :from="fromDateISOString" :to="toDateISOString" unique="true">
          <template #title>Unique Logins</template>
        </logins>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import TopActivities from '@/components/charts/TopActivities.vue';
import DateRangeSelector from "@/components/charts/DateRangeSelector.vue";
import Logins from "@/components/charts/Logins.vue";
import TopTags from "@/components/charts/TopTags.vue";

export default {
  components: {
    TopTags,
    Logins,
    DateRangeSelector,
    TopActivities,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      from: new Date(new Date().setDate(new Date().getDate() -  7)),
      to: new Date(),
    }
  },
  computed: {
    fromDateISOString() {
      let date = new Date(this.from);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
    toDateISOString() {
      let date = new Date(this.to);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
  },
  mounted() {
    this.from.setHours(0,0,0,0)
    this.to.setHours(23,59,59)
  },
  methods: {
    updateDateRange(from, to) {
      this.from = from
      this.to = to
    }
  }
}
</script>

<style>

</style>
